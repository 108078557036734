import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { PatternErrorsEnum } from '../models/enums';
import moment from 'moment/moment';
import { DadataSuggestion } from 'src/app/modules/client/models/interfaces/dadata-suggestion';
import { AddressByHand } from 'src/app/modules/client/models/interfaces/address-by-hand';

export const getControl = (form: FormGroup | AbstractControl, control: string): FormControl => {
  return form.get(control) as FormControl;
};

export const getArray = (form: FormGroup, control: string): FormArray => {
  return form.get(control) as FormArray;
};

export const getPatternError = (control: FormControl): string | null => {
  let result: string | null = null;
  if (control.hasError('pattern')) {
    const pattern = control.getError('pattern')?.requiredPattern;
    Object.entries(PatternErrorsEnum).forEach(([k, v]) => {
      if (k === pattern) return (result = v);
      return 'Введено недопустимое значение';
    });
  }
  return result;
};

export const convertDateForLoan = (date: any): string => {
  return moment(date, 'YYYY-MM-DD').format('DDMMYYYY');
};

export const convertDateForLoanForm = (date: any): string | null => {
  if (date) {
    return moment(date, 'DDMMYYYY').format('YYYY-MM-DD');
  } else {
    return null;
  }
};

export const stringNumberToNumber = (p: string | number): number => {
  // remove extra spaces from string and convert it to number
  if (typeof p === 'string') return Number(p.replace(/\s+/g, '')).valueOf();
  return p;
};

export const formatPhone = (phone: string): string => {
  if (phone?.substring(0, 2) === '+7') return '+' + phone?.replace(/[^A-Za-z0-9]/g, '');
  return '+7' + phone?.replace(/[^A-Za-z0-9]/g, '');
};

export const setAddressByHandForDadataSuggestion = (
  addressByHand: AddressByHand,
): DadataSuggestion => {
  const unrestricted_value =
    addressByHand.postal_code +
    ' ' +
    addressByHand.country +
    ' ' +
    addressByHand.region +
    (addressByHand.area ? ' ' + addressByHand.area : '') +
    (addressByHand.settlement_type_full ? ' ' + addressByHand.settlement_type_full + ' ' : '') +
    (addressByHand.settlement && addressByHand.settlement_type_full
      ? addressByHand.settlement + ''
      : '') +
    (addressByHand.settlement && !addressByHand.settlement_type_full
      ? ' ' + addressByHand.settlement + ''
      : '') +
    (addressByHand.city_type_full ? ' ' + addressByHand.city_type_full + ' ' : '') +
    (addressByHand.city && addressByHand.city_type_full ? addressByHand.city + '' : '') +
    (addressByHand.city && !addressByHand.city_type_full ? ' ' + addressByHand.city + '' : '') +
    (addressByHand.street_type ? ' ' + addressByHand.street_type + ' ' : '') +
    (addressByHand.street && addressByHand.street_type ? addressByHand.street + '' : '') +
    (addressByHand.street && !addressByHand.street_type ? ' ' + addressByHand.street + '' : '') +
    (addressByHand.house ? ' д ' + addressByHand.house + '' : '') +
    (addressByHand.building ? ' к ' + addressByHand.building + '' : '') +
    (addressByHand.block ? ' стр ' + addressByHand.block + '' : '') +
    (addressByHand.flat ? ' кв ' + addressByHand.flat : '');

  const address = {
    value: '',
    unrestricted_value: unrestricted_value,
    data: addressByHand,
  };
  return address;
};

export const MY_FORMATS = {
  parse: {
    dateInput: ['l', 'LL'],
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
