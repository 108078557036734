import { IConfig } from 'ngx-mask';

export enum MasksEnum {
  phone = '(000) 000-00-00',
  fourDigits = '0000',

  passport = '00 00 000000',
  divisionCode = '000-000',

  carPlateNumber = 'T 000 TT 000||T 000 TT 00',
  vin = 'VVVVVVVVVVVVVVVVV',
  pts = '00TT 000000',
  epts = '000000000000000',
  departmentCodeGIBDD = 'UUUUU 0000',
  price = '000||0 000||00 000||000 000||0 000 000||00 000 000',
  totalPrice = '0||00||000||0 000||00 000||000 000||0 000 000||00 000 000||000 000 000',
  income = '000||0 000||00 000||000 000||0 000 000',
  incomeSum = '0||N00||N 000||N0 000||N00 000||N 000 000||N0 000 000',
  loanPrice = '0||N00||N 000||N0 000||N00 000||N 000 000||N0 000 000',
  floatPrice = '000.00||0 000.00||00 000.00||000 000.00||0 000 000.00||00 000 000.00||000 000 000.00',
  mileage = '000||0 000||00 000||000 000||0 000 000',
  date = '00.00.0000',
  twoDigitsMax = '0||00',
  driversLicenseNumber = '0000 000000',
  previousDriversLicenseNumber = '00VV 000000',
  creditCardNumber = '0000 0000 0000 0000',
  hiddenCreditCardNumber = '0000 XXXX XXXX 0000',
  creditCardEndDate = '00/0000',
  creditCardCVV = '000',
  stsNumber = 'SS SS SSSSSS',
  latString = 'LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL',
  time = '00:00',
  number = '000000',
  riskSum = '0000000',
  email = 'A*@A*.SSS',
  year_term = 'E',
}

export const CUSTOM_PATTERNS: IConfig['patterns'] = {
  U: { pattern: new RegExp('[а-яА-Я]') },
  0: { pattern: new RegExp('[0-9]') },
  T: { pattern: new RegExp('[авеклмнорстухabekmhopctyxoАВЕКЛМНОРСТУХABEKMHOPCTYX0]') },
  S: { pattern: new RegExp('[а-яА-Я0-9]') },
  V: { pattern: new RegExp('[А-Я0-9]') },
  Y: { pattern: new RegExp('[0-7]') },
  E: { pattern: new RegExp('[1-7]') },
};

export const CUSTOM_PATTERNS_FOR_VIN: IConfig['patterns'] = {
  V: { pattern: new RegExp('^[A-HJ-NPR-Za-hj-npr-z0-9]+$') },
};

export const CUSTOM_PATTERNS_LAT: IConfig['patterns'] = {
  L: { pattern: new RegExp('^[a-zA-Z]+$') },
};

export const CUSTOM_PATTERNS_FOR_NUMBERS: IConfig['patterns'] = {
  0: { pattern: new RegExp('[0-9]+$') },
  N: { pattern: new RegExp('[1-9]+$') },
};
export const CUSTOM_PATTERNS_FOR_TIME: IConfig['patterns'] = {
  0: { pattern: new RegExp('[0-9]+$') },
};
