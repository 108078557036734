import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, EMPTY, Observable, Subject } from 'rxjs';
import { routingPathEnum } from '../models';
import { PersistenceService } from './persistence.service';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class SharedService {
  sidebarData$: BehaviorSubject<{ header: string; data: any[] }> = new BehaviorSubject({
    header: '',
    data: Array.of(),
  });
  clientSurveyStepBarData$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  carOptionsStepBarData$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  _userActionOccured: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private persistenceService: PersistenceService,
    private dialog: MatDialog,
  ) {}

  /// Use this refresh$ observable in data services to indicate when data refresh is needed
  private _refresh = new BehaviorSubject<void>(undefined);

  get refresh$(): Observable<any> {
    return this._refresh.asObservable();
  }

  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable();
  }

  public notifyUserAction() {
    this._userActionOccured.next();
  }

  public logOutUser() {
    this.persistenceService.set('lastUrl', this.router.url);
    this.router.navigateByUrl(`/${routingPathEnum.BrokerAuthentication}`);
    localStorage.removeItem('accessToken');
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('reload', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    this._refresh.next();
  }

  emitRefresh() {
    this._refresh.next();
  }

  loadingError(error: any) {
    if (error) {
      this.openDialog('Ошибка при загрузке данных');
    }
  }

  actionSuccess(done: boolean, message: string) {
    if (done) {
      this.openDialog(message);
    }
  }

  actionError(error: any, message: string) {
    if (error) {
      this.openDialog(message);
    }
  }

  openDialog(message: string) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageOne: message,
        confirmButton: true,
        buttonTitle: 'Ок',
        withoutTimer: true,
      },
    });
  }

  catchError() {
    return catchError((response) => {
      console.error(response);
      if (response) {
        if (response.status == 500 || response.status == 502 || response.status == 503) {
          this.openDialog('Сервис временно недоступен, попробуйте позже');
        } else {
          if (
            typeof response.error?.error?.message == 'string' ||
            Array.isArray(response.error?.error?.data)
          ) {
            if (typeof response.error?.error?.message == 'string') {
              this.openDialog(response.error?.error?.message);
            }

            if (Array.isArray(response.error?.error?.data)) {
              this.openDialog(response.error?.error?.data[0]);
            }
          } else if (
            (typeof response.error?.message == 'string' || Array.isArray(response.error?.data)) &&
            (response.status == 400 || response.status == 403 || response.status == 422)
          ) {
            if (typeof response.error?.message == 'string') {
              this.openDialog(response.error?.message);
            }

            if (Array.isArray(response.error?.data)) {
              this.openDialog(response.error?.data[0]);
            }
          } else {
            this.openDialog('Что-то пошло не так. Пожалуйста, попробуйте позже.');
          }
        }
        return EMPTY;
      } else return EMPTY;
    });
  }
}
