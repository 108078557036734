export enum RegexEnum {
  firstName = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  lastName = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  previousLastName = `^[А-Яа-яЁё]*([- ]?[А-Яа-яЁё]*)*$`,
  patronymic = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  cityType = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  country = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  region = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  city = `^[А-Яа-яЁё]+([- .,()0-9]+[А-Яа-яЁё.,()0-9]+)*\\s*$`,
  area = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  whoGave = `^ГИБДД\\s\\d{4}$`,
  localityType = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  locality = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  streetType = `^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$`,
  street = `^[а-яА-ЯёЁ0-9 -]*$`,
  house = `^[А-Яа-яЁё/0-9]*$`,
  building = `^[а-яА-ЯёЁ0-9 -]*$`,
  houseExt = `^[А-Яа-яЁё/0-9]*$`,
  flat = `^[А-Яа-яЁё/0-9,]*$`,
  postCode = `^d{6}$`,
  email = `^[A-Za-z0-9]+[a-zA-Z0-9_.-]+@+([a-zA-Z0-9-]{2,})+(?:\\.[a-zA-Z0-9-]{2,})+$`,
  phone = `[- +()0-9]+`,
  simpleDate = '^(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\\d{4}$',
  gender = `^[А-Я][а-я]{0,6}$`,
  digits = `^[0-9]+$`,
  vin = '^(?=[0-9A-HJ-NPR-Za-hj-npr-z]*[A-HJ-NPR-Za-hj-npr-z])[0-9A-HJ-NPR-Za-hj-npr-z]{13}[0-9]{4}$',
  passportIssuedBy = `^[-А-Яа-яЁё,.№0-9 ]*$`,
  serviceCentersGroupName = `^[А-Яа-яЁё]+([ ]+[0-9]+)+$`,
  cyrillicSymbols = `^[А-Яа-яЁё0-9 .,!?:'";-]*$`,
  extraCyrillicContactName = `^[А-Яа-яЁё -]*$`,
  extraWhoIsTheContact = `^[А-Яа-яЁё0-9]+([- .,]\s?[А-Яа-яЁё0-9]+)*$`,
  paymentPurpose = '^(.*\\s)?НДС(\\s.*)?$',
  address = `^[а-яА-ЯёЁ0-9]+([- ]?[а-яА-ЯёЁ.,()0-9]+)*$`,
  date = '(3[01]|[12][0-9]|0?[1-9])(\\/|-)(1[0-2]|0?[1-9])\\2([0-9]{2})?[0-9]{2}',
}

export const DATE_SYMBOL_PATTERN: Array<RegExp | string> = [
  /^[0-3]$/,
  /^[0-9]$/,
  '-',
  /^[0-1]$/,
  /^[0-9]$/,
  '-',
  /^[0-9]$/,
  /^[0-9]$/,
  /^[0-9]$/,
  /^[0-9]$/,
];

export enum PatternErrorsEnum {
  '^(.*\\s)?НДС(\\s.*)?$' = 'Текст должен содержать "НДС"',
  '^[а-яА-ЯёЁ -]*$' = 'Используйте только кириллицу, дефис и пробел',
  '^[А-Яа-яЁё]+([- ]?[А-Яа-яЁё]+)*$' = 'Используйте только кириллицу',
  '^[А-Яа-яЁё]*([- ]?[А-Яа-яЁё]*)*$' = 'Используйте только кириллицу',
  '^[А-Я][а-я]{0,30}$' = 'Используйте только кириллицу',
  '^[А-Яа-я0-9 .,!?:\'";]*$' = 'Используйте только кириллицу',
  '^[А-Яа-яЁё -]*$' = 'Используйте только кириллицу',
  '^[0-9]+$' = 'Используйте только цифры',
  '^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]d{4}$' = 'Введите полную дату',
  '^(?=[0-9A-HJ-NPR-Za-hj-npr-z]*[A-HJ-NPR-Za-hj-npr-z])[0-9A-HJ-NPR-Za-hj-npr-z]{13}[0-9]{4}$' = 'Значение должно содержать 17 символов - цифры, латинские буквы кроме I,O,Q, последние 4 символа цифры',
  '^[А-Яа-яЁё,. ]*$' = 'Используйте только кириллицу, точку и запятую',
  '^[-А-Яа-яЁё,. ]*$' = 'Используйте только кириллицу, точку, запятую и дефис',
  '^[-А-Яа-яЁё,.№0-9 ]*$' = 'Используйте только кириллицу, числа, точку, знак номера, запятую и дефис',
  '^[А-Яа-яЁё]+([ ]+[0-9]+)+$' = 'Используйте для ввода значение формата "Группа 2"',
  '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$' = 'Используйте латиницу, цифры, символы _ . -',
  '^[А-Яа-яЁё0-9 -]*$' = 'Используйте кириллицу, цифры, дефис и пробел',
  '^[А-Яа-яЁё]+([- .,()0-9]+[А-Яа-яЁё.,()0-9]+)*\\s*$' = 'Используйте кириллицу, цифры, точку, запятую, дефис, пробел, круглые скобки',
  '^[А-Яа-яЁё0-9]+([- .,]s?[А-Яа-яЁё0-9]+)*$' = 'Используйте только кириллицу, числа, точку, запятую и дефис',
  '^[а-яА-ЯёЁ0-9]+([- ]?[а-яА-ЯёЁ.,()0-9]+)*$' = 'Используйте кириллицу, цифры, точку, запятую, дефис, пробел, круглые скобки',
  '^ГИБДД\\s\\d{4}$' = 'Значение должно содержать не менее 4 цифр',
}

export const addressRegex = /^\d{6},\s*.*$/;
// /^\d{6},\s*[-а-яА-ЯЁё ]+\s*,\s*[-а-яА-ЯЁё ]+\s*,\s*[-а-яА-ЯЁё ]+\s*,\s*[-а-яА-ЯЁё ]+\s*,\s*[-А-Яа-яЁё/0-9 ]+,*\s*[-А-Яа-яЁё/0-9 ]*,*\s*[-А-Яа-яЁё/0-9 ]*,*\s*[-А-Яа-яЁё/0-9 ]*$/;
// \s*[-а-яА-ЯЁё]+\.\s*[-а-яА-ЯЁё]+\s*,\s*[-а-яА-ЯЁё]+\.\s*[-а-яА-ЯЁё0-9. ]+(\s+[а-яА-ЯЁё]+)?\s*,\s*[-а-яА-ЯЁё]*\.\s*[А-Яа-яЁё/0-9]+\s*,*\s*[-а-яА-ЯЁё]*\.*\s*[А-Яа-яЁё/0-9]*,*\s*[-а-яА-ЯЁё]*\.*\s*[А-Яа-яЁё/0-9]
export const workingAddressRegex =
  /^\d{6},\s*[-а-яА-ЯЁё ]+\s*,\s*[-а-яА-ЯЁё ]+\s*,\s*[-а-яА-ЯЁё ]+\s*,\s*[-а-яА-ЯЁё ]+\s*,\s*[-А-Яа-яЁё/0-9 ]+$/;

// /^\d{6},\s*[-а-яА-ЯЁё]+\s*,\s*[-а-яА-ЯЁё]+\.\s*[-а-яА-ЯЁё]+\s*,\s*[-а-яА-ЯЁё]+\.\s*[-а-яА-ЯЁё0-9. ]+(\s+[а-яА-ЯЁё]+)?\s*,\s*д\.\s*[А-Яа-яЁё/0-9]+$/;
