import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'tes-select',
  templateUrl: './tes-select.component.html',
  styleUrls: ['./tes-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TesSelectComponent),
      multi: true,
    },
  ],
})
export class TesSelectComponent<T extends Record<string, any>>
  implements ControlValueAccessor, OnInit, AfterViewChecked, OnDestroy
{
  @Input() public list: T[] = [];
  @Input() public style: 'filled' | 'underline' = 'filled';
  @Input() public readonly = false;

  @Input() public label!: string;
  @Input() public placeholder!: string;
  @Input() public value!: any;
  @Input() public control!: FormControl;
  @Input() public initialTouched = false;
  @Input() public disabled = false;
  @Input() public showRequiredAlways = false;
  @Input() public allowEmptyValue = false;
  @Input() public displayField!: keyof T;
  @Input() public valueField!: keyof T;
  @Output() change = new EventEmitter<any>();

  public status: 'success' | 'danger' = 'success';

  private _unsubscribeAll: Subject<void> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit() {
    this.statusChange();
    if (this.control.hasValidator(Validators.required) && !this.control.value) {
      this.status = 'danger';
    }
  }

  statusChange() {
    this.control?.statusChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value: string) => {
      this.status = value === 'VALID' || value === 'DISABLED' ? 'success' : 'danger';
    });

    this.status = this.control?.errors === null ? 'success' : 'danger';
  }

  ngAfterViewChecked() {
    if (this.initialTouched) this.onControlTouch();
  }

  public onChange: Function = () => {};
  public onTouched: Function = () => {};

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: T) {
    this.value = value;
    this.change.emit(value);
    this.cdr.markForCheck();
  }

  public onModelChange(e: T) {
    this.value = e;
    this.onTouched();
    this.change.emit(e);
    this.onChange(this.value);
    this.statusChange();
  }

  public onControlTouch() {
    this.onTouched();
    this.statusChange();
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  isObjectList(): boolean {
    return Array.isArray(this.list) && this.list.length > 0 && typeof this.list[0] === 'object';
  }
}
