<button
  mat-button
  type="button"
  class="tes-button"
  [ngClass]="'tes-button-' + color"
  [disabled]="disabled"
  [ngStyle]="transmittedStyles"
  [class.disabled]="!disabled"
>
  <mat-icon class="material-icons-outlined" inline *ngIf="iconPlace === 'pre'">{{ icon }}</mat-icon>
  {{ title }}
  <mat-icon inline *ngIf="iconPlace === 'post'">{{ icon }}</mat-icon>
</button>
